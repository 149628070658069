// material-ui
import { createTheme } from "@mui/material/styles";

// third-party
import { presetPalettes } from "@ant-design/colors";

// project import
import ThemeOption from "./theme";

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const ThemePalette = (mode) => {
  const colors = presetPalettes;

  // Define light and dark mode color sets
  const lightGrey = [
    "#ffffff",
    "#fafafa",
    "#f5f5f5",
    "#f0f0f0",
    "#d9d9d9",
    "#bfbfbf",
    "#8c8c8c",
    "#595959",
    "#262626",
    "#141414",
    "#000000",
  ];

  const darkGrey = [
    "#000000",
    "#141414",
    "#262626",
    "#333333",
    "#4f4f4f",
    "#6b6b6b",
    "#8c8c8c",
    "#bfbfbf",
    "#d9d9d9",
    "#f0f0f0",
    "#ffffff",
  ];

  // Choose colors based on the theme mode
  const greyPrimary = mode === "dark" ? darkGrey : lightGrey;
  const greyAscent =
    mode === "dark"
      ? ["#141414", "#434343", "#bfbfbf", "#fafafa"]
      : ["#fafafa", "#bfbfbf", "#434343", "#1f1f1f"];
  const greyConstant = ["#fafafb", "#e6ebf1"];

  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

  // Define primary color (same for both modes, but can be changed if needed)
  colors.primary = ["#0088CC"];

  const paletteColor = ThemeOption(colors);

  console.log("Arrived here");
  console.log(paletteColor);

  return createTheme({
    palette: {
      mode,
      common: {
        black: "#000",
        white: "#fff",
      },
      ...paletteColor,
      primary: {
        main: "#0088CC", // Telegram Blue
        contrastText: "#E1E9EE",
      },
      background: {
        default: "#17212B", // Telegram Dark Background
        paper: "#232E3C", // Cards & Menus
      },
      text: {
        primary: "#E1E9EE", // Main Text Color
        secondary: "#AEBAC1", // Secondary Text
      },
      divider: "#2B3B4B", // Border / Divider Color
      action: {
        hover: "#2B3B4B",
        selected: "#0088CC33",
        disabled: "#AEBAC1",
      },
      error: {
        main: "#F55050",
      },
      success: {
        main: "#4CAF50",
      },
    },
  });
};

export default ThemePalette;
