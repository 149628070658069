import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { createSavingsFailed, createSavingsRequest, createSavingsSuccess, deleteSavingsFailed, deleteSavingsRequest, deleteSavingsSuccess, editSavingsFailed, editSavingsRequest, editSavingsSuccess, listSavingsFailed, listSavingsRequest, listSavingsSuccess } from "store/reducers/savings";
import {toast} from "react-toastify";


export const listSavingsEffect = (search, pageSize, page, currency) => {
    return (dispatch) => {
        dispatch(listSavingsRequest());
        API.get(URLS.SAVINGS.list(search, pageSize, page, currency))
            .then((resp) => {
                const { data } = resp;
                dispatch(listSavingsSuccess(data));
            })
            .catch((error) => {
                toast.error(
                    error.response.data.detail ??
                        "Loading savings error, please try later."
                );
                dispatch(listSavingsFailed());
            });
    };
};

export const createSavingsEffect = (savings, onSuccess) => {
    return (dispatch) => {
        dispatch(createSavingsRequest());
        API.post(URLS.SAVINGS.create, savings)
            .then((resp) => {
                const { data } = resp;
                dispatch(createSavingsSuccess(data));
                toast.success("Savings successfully created");
                if (onSuccess) {
                    onSuccess(data);
                }
            })
            .catch((error) => {
                toast.error(
                    error.response.data.detail ??
                        "Savings creation failed, please try later."
                );
                dispatch(createSavingsFailed());
            });
    };
};

export const editSavingsEffect = (savings, onSuccess) => {
    return (dispatch) => {
        dispatch(editSavingsRequest());
        API.patch(URLS.SAVINGS.edit(savings.id), savings)
            .then((resp) => {
                const { data } = resp;
                dispatch(editSavingsSuccess(data));
                toast.success("Savings data successfully updated");

                if (onSuccess) {
                    onSuccess(data);
                }
            })
            .catch((error) => {
                toast.error(
                    error.response.data.detail ??
                        "Savings edition error, please try later."
                );
                dispatch(editSavingsFailed());
            });
    };
};

export const deleteSavingsEffect = (savings, onSuccess) => {
    return (dispatch) => {
        dispatch(deleteSavingsRequest());
        API.delete(URLS.SAVINGS.delete(savings.id))
            .then((resp) => {
                const { data } = resp;
                dispatch(deleteSavingsSuccess(savings));
                toast.success("Savings data successfully deleted");
                if (onSuccess) {
                    onSuccess(data);
                }
            })
            .catch((error) => {
                toast.error(
                    error.response.data.detail ??
                        "Savings deletion error, please try later."
                );
                dispatch(deleteSavingsFailed());
            });
    };
};