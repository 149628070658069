import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseOutlined from "@ant-design/icons";

import { Link as RouterLink } from "react-router-dom";

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  MenuItem,
  Select,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// import { useHistory } from 'react-router';

// project import
// import FirebaseSocial from './FirebaseSocial';
import AnimateButton from "components/@extended/AnimateButton";

// assets
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

// actions
import { authLoginEffect } from "store/effects/authEffects";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import LoadingButton from "@mui/lab/LoadingButton";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { toast } from "react-toastify";
import { evaluateClosingPrice } from "../utils";
import SelectSymbol from "../components/SelectSymbol";
import { CircularProgress } from "../../../../../../node_modules/@mui/material/index";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined style={{ color: "#000" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NewPosition({
  wallet,
  isEdition,
  actionButton,
  handleSuccess,
  wallets,
}) {
  const [open, setOpen] = React.useState(false);
  const [qrCode, setQrCode] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [symbols, setSymbols] = React.useState([]);
  const [loadingPrice, setLoadingPrice] = React.useState(false);
  const [selectedSymbol, setSelectedSymbol] = React.useState(); 

  const [showPassword, setShowPassword] = React.useState(false);
  const {
    login_request,
    auth: { user },
  } = useSelector((state) => state.userAuth);

  const handleChangeSymbol = (symb) => {
    setSelectedSymbol(symb)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const { userId } = useParams();

  const handleChangeQrCode = (event) => {
    setQrCode(event.target.files[0]);
  };

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    API.get(URLS.SYMBOLS.ALL).then((resp) => {
      console.log(resp);
      setSymbols(resp.data.symbols);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <div>
      <div onClick={handleClickOpen}>{actionButton()}</div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {isEdition ? "Edit position" : "New position"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              pWallet: wallet?.wallet?.id ?? "",
              symbol: wallet?.symbol ?? "",
              quantity: wallet?.quantity ?? "",
              openingPrice: wallet?.opening_price ?? "0",
              closingPrice: wallet?.closing_price ?? "0",
              profitLoss: wallet?.profit_lost ?? "0",
              positionType: wallet?.position_type ?? "",
              commission: wallet?.commission ?? "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              pWallet: Yup.string().max(255).required("This field is required"),
              symbol: Yup.string().max(255).required("This field is required"),
              quantity: Yup.string()
                .max(255)
                .required("This field is required"),
              openingPrice: Yup.string()
                .max(255)
                .required("This field is required"),
              positionType: Yup.string().required("This field is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                if(!selectedSymbol) {
                  setErrors({ submit: "Please select a symbol" });
                  toast.error("Please select a symbol");
                  return;
                }
                setLoading(true);
                // const data = { ...values, type: "USER", user:  userId};
                let data = {
                  "wallet": values.pWallet,
                  "quantity": parseFloat(values.quantity),
                  "opening_price": parseFloat(values.openingPrice),
                  "closing_price": parseFloat(values.closingPrice) ?? 0,
                  "position_type": values.positionType,
                  "symbol": selectedSymbol,
                  "created_by": user.id,
                  "commission": values.commission
                }
                data["profit_lost"] =  parseFloat(values.profitLoss);

                // if(values.profitLoss) {
                //   data["status"] =  "CLOSED";
                // }

                if (wallet && isEdition) {
                  API.patch(URLS.POSITIONS.EDIT(wallet?.id), data).then(
                    (reps) => {
                      setStatus({ success: true });
                      setSubmitting(true);
                      if (handleSuccess) {
                        handleSuccess(reps.data);
                      }
                      setLoading(false);
                      handleClose();
                      toast.success("Position edited successfuly");
                    }
                  ).catch ((err) =>{
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                    setLoading(false);
                  });
                } else {
                  API.post(URLS.POSITIONS.CREATE, data).then((reps) => {
                    setStatus({ success: true });
                    setSubmitting(true);
                    if (handleSuccess) {
                      handleSuccess(reps.data);
                    }
                    setLoading(false);
                    handleClose();
                    toast.success("Position created successfuly");
                  }).catch ((err) =>{
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  });
                }
              } catch (err) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="pWallet-login">Wallet</InputLabel>
                      <Select
                        id="pWallet-login"
                        // type="email"
                        value={values.pWallet}
                        name="pWallet"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Currency"
                        fullWidth
                        error={Boolean(touched.pWallet && errors.pWallet)}
                      >
                        {wallets.map((wal) => {
                          return (
                            <MenuItem key={wal.id} value={wal.id}>
                              {Number(wal.balance).toFixed(2)} {wal.currency}
                            </MenuItem>
                          );
                        })}
                        {/* <MenuItem value="ETH">Ethereum</MenuItem>
                        <MenuItem value="USDT">Tether</MenuItem>
                        <MenuItem value="USD">United states dollar</MenuItem>
                        <MenuItem value="EUR">Euro</MenuItem> */}
                      </Select>
                      {touched.pWallet && errors.pWallet && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-pWallet-login"
                        >
                          {errors.pWallet}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="symbol-login">Actif</InputLabel>
                      <SelectSymbol setLoading={setLoadingPrice} onChange={handleChangeSymbol} setFieldValue={setFieldValue } />
                      {/* <OutlinedInput
                        fullWidth
                        error={Boolean(touched.symbol && errors.symbol)}
                        id="symbol-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.symbol}
                        // type={"number"}
                        name="symbol"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Order Actif"
                      /> */}
                      {touched.symbol && errors.symbol && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-symbol-login"
                        >
                          {errors.symbol}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="quantity-login">Quantity</InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.quantity && errors.quantity)}
                        id="quantity-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.quantity}
                        type={"number"}
                        name="quantity"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Order quantity"
                      />
                      {touched.quantity && errors.quantity && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-quantity-login"
                        >
                          {errors.quantity}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="openingPrice-login">
                        Opening price
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.openingPrice && errors.openingPrice
                        )}
                        id="openingPrice-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.openingPrice}
                        type="number"
                        min={0}
                        name="openingPrice"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Position opening price"
                      />
                      {loadingPrice && <CircularProgress size={20} />}
                      {touched.openingPrice && errors.openingPrice && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-openingPrice-login"
                        >
                          {errors.openingPrice}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="openingPrice-login">
                        Profit Loss
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.profitLoss && errors.profitLoss
                        )}
                        id="profitLoss-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.profitLoss}
                        type="number"
                        min={0}
                        
                        name="profitLoss"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          setFieldValue("closingPrice",  evaluateClosingPrice(values.quantity, values.openingPrice, parseFloat(event.target.value)));
                        }}
                        placeholder="Profit Loss"
                      />
                      {touched.profitLoss && errors.profitLoss && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-profitLoss-login"
                        >
                          {errors.profitLoss}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  {/* <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="qrCode-login">QR code</InputLabel>
                      <OutlinedInput
                        fullWidth
                        id="qr-code-login"
                        // type={showPassword ? 'text' : 'password'}
                        // value={values.balance}
                        type="file"
                        onChange={handleChangeQrCode}
                        placeholder="QR code image"
                        inputProps={{ accept: "image/*" }}
                      />
                    </Stack>
                  </Grid> */}

                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="positionType-login">
                        Position type
                      </InputLabel>
                      <Select
                        id="positionType-login"
                        // type="email"
                        value={values.positionType}
                        name="positionType"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Currency"
                        fullWidth
                        error={Boolean(
                          touched.positionType && errors.positionType
                        )}
                      >
                        <MenuItem value="BUY">Buy</MenuItem>
                        <MenuItem value="SELL">Sell</MenuItem>
                      </Select>
                      {touched.positionType && errors.positionType && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-positionType-login"
                        >
                          {errors.positionType}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="closingPrice-login">
                        Closing price
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.closingPrice && errors.closingPrice
                        )}
                        id="closingPrice-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.closingPrice}
                        disabled={!isEdition}
                        type="number"
                        min={0}
                        name="closingPrice"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          // setFieldValue("profitLoss", event.target.value - (values.openingPrice))
                        }}
                        placeholder="Position closing price"
                      />
                      {touched.closingPrice && errors.closingPrice && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-closingPrice-login"
                        >
                          {errors.closingPrice}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="commission-login">
                        Commission
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.commission && errors.commission
                        )}
                        id="commission-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.commission}
                        disabled={!isEdition}
                        type="number"
                        min={0}
                        name="commission"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Company profit"
                      />
                      {touched.commission && errors.commission && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-commission-login"
                        >
                          {errors.commission}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12} sx={{ mt: "0" }}></Grid>
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{ height: "0px", visibility: "hidden" }}
                  >
                    <AnimateButton>
                      <Button
                        disableElevation
                        disabled={login_request}
                        id="submitButton"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                        {/* {login_request && <CircularLo /> } */}
                      </Button>
                    </AnimateButton>
                  </Grid>
                  {/* <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption"> Login with</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={() => {
              const submitButton = document.getElementById("submitButton");
              if (submitButton) {
                submitButton.click();
              }
            }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
