import * as React from 'react';
import {
    Stack,
    Box,
    OutlinedInput,
    Grid,
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Autocomplete,
    InputLabel,
    TextField,
    FormHelperText,
    IconButton
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { EditOutlined } from '@ant-design/icons';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// import FirebaseSocial from './FirebaseSocial';
import AnimateButton from 'components/@extended/AnimateButton';
import { useDispatch, useSelector } from 'react-redux';
import { createProspectEffect } from 'store/effects/prospectEffects';
import _, { create } from 'lodash';
import { toast } from 'react-toastify';
import { createSavingsEffect, editSavingsEffect } from 'store/effects/savingsEffect';

export default function ManageSavings(props) {
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [country, setCountry] = React.useState();
    const { groups, toEdit } = props;

    const { create_savings_request, edit_savings_request } = useSelector((state) => state.savings);

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const dispatch = useDispatch();

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
           {!toEdit ? <Button sx={{ marginLeft: '1rem', height: '100%' }} onClick={handleClickOpen('body')} variant="contained">
                New savings plan
            </Button>
            : 
            <IconButton onClick={handleClickOpen('body')}>
                <EditOutlined />
            </IconButton>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    {toEdit ? 'Edit savings plan' : 'New savings plan'}
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <Grid id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
                        <Formik
                            initialValues={{
                                currency: 'EUR',
                                name: toEdit?.name ?? '',
                                flexible_perc: toEdit?.flexible_perc ?? 0,
                                locked_1_month_perc: toEdit?.locked_1_month_perc ?? 0,
                                locked_1_quarter_perc: toEdit?.locked_1_quarter_perc ?? 0,
                                locked_6_months_perc: toEdit?.locked_6_months_perc ?? 0,
                                locked_1_year_perc: toEdit?.locked_1_year_perc ?? 0,
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required('Name is required'),
                                flexible_perc: Yup.number().required('Flexible percentage is required'),
                                locked_1_month_perc: Yup.number().required('1 month percentage is required'),
                                locked_1_quarter_perc: Yup.number().required('1 quarter percentage is required'),
                                locked_6_months_perc: Yup.number().required('6 months percentage is required'),
                                locked_1_year_perc: Yup.number().required('1 year percentage is required'),
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                setStatus({ success: false });
                                setSubmitting(false);
                                const newPropspectDatas = {
                                    id: toEdit?.id,
                                    name: values.name,
                                    currency: "EUR",
                                    flexible_perc: values.flexible_perc,
                                    locked_1_month_perc: values.locked_1_month_perc,
                                    locked_1_quarter_perc: values.locked_1_quarter_perc,
                                    locked_6_months_perc: values.locked_6_months_perc,
                                    locked_1_year_perc: values.locked_1_year_perc,
                                };
                                if (toEdit) {
                                    dispatch(editSavingsEffect(newPropspectDatas, handleClose));
                                } else {
                                    dispatch(createSavingsEffect(newPropspectDatas, handleClose));
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="email-login">Plan name</InputLabel>
                                                <OutlinedInput
                                                    id="planName"
                                                    type="text"
                                                    value={values.name}
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter Plan Name"
                                                    fullWidth
                                                    error={Boolean(touched.name && errors.name)}
                                                />
                                                {touched.name && errors.name && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                                        {errors.name}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="email-login">Flaxible percentage</InputLabel>
                                                <OutlinedInput
                                                    id="flexible-percentage"
                                                    type="number"
                                                    value={values.flrxible_perc}
                                                    name="flrxible_perc"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter Flexible percentage"
                                                    fullWidth
                                                    error={Boolean(touched.flexible_perc && errors.flexible_perc)}
                                                />
                                                {touched.flexible_perc && errors.flexible_perc && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                                        {errors.flexible_perc}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="password-login">Locked 1 month percentage</InputLabel>
                                                <OutlinedInput
                                                    id="email-one-month"
                                                    type="numbeer"
                                                    value={values.locked_1_month_perc}
                                                    name="locked_1_month_perc"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter 1 month percentage"
                                                    fullWidth
                                                    error={Boolean(touched.locked_1_month_perc && errors.locked_1_month_perc)}
                                                />
                                                {touched.locked_1_month_perc && errors.locked_1_month_perc && (
                                                    <FormHelperText locked_1_month_perc id="standard-weight-helper-text-password-login">
                                                        {errors.locked_1_month_perc}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="locked_1_quarter_perc">Locked 1 quarter percentage</InputLabel>
                                                <OutlinedInput
                                                    id="locked_1_quarter_perc"
                                                    type="text"
                                                    value={values.locked_1_quarter_perc}
                                                    name="locked_1_quarter_perc"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter 1 quarter percentage"
                                                    fullWidth
                                                    error={Boolean(touched.locked_1_quarter_perc && errors.locked_1_quarter_perc)}
                                                />
                                                {touched.locked_1_quarter_perc && errors.locked_1_quarter_perc && (
                                                    <FormHelperText error id="standard-locked_1_quarter_perc-login">
                                                        {errors.locked_1_quarter_perc}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="locked_6_months_perc">Locked 6 months percentage</InputLabel>
                                                <OutlinedInput
                                                    id="locked_6_months_perc"
                                                    type="text"
                                                    value={values.locked_6_months_perc}
                                                    name="locked_6_months_perc"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter 6 months percentage"
                                                    fullWidth
                                                    error={Boolean(touched.locked_6_months_perc && errors.locked_6_months_perc)}
                                                />
                                                {touched.locked_6_months_perc && errors.locked_6_months_perc && (
                                                    <FormHelperText error id="standard-locked_6_months_perc-login">
                                                        {errors.locked_6_months_perc}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="loakced_1_year_perc">Locked 1 year percentage</InputLabel>
                                                <OutlinedInput
                                                    id="locked_1_year_perc"
                                                    type="text"
                                                    value={values.locked_1_year_perc}
                                                    name="locked_1_year_perc"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter 1 year percentage"
                                                    fullWidth
                                                    error={Boolean(touched.locked_1_year_perc && errors.locked_1_year_perc)}
                                                />
                                                {touched.locked_1_year_perc && errors.locked_1_year_perc && (
                                                    <FormHelperText error id="standard-locked_1_year_perc-login">
                                                        {errors.locked_1_year_perc}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AnimateButton>
                                                <Button
                                                    disableElevation
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ visibility: 'hidden' }}
                                                    id="submit"
                                                >
                                                    {/* {login_request && <CircularLo /> } */}
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption"> Login with</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <LoadingButton
                        loading={create_savings_request || edit_savings_request}
                        onClick={() => {
                            let submitBtn = document.getElementById('submit');
                            if (submitBtn) {
                                submitBtn.click();
                            }
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Create
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
