import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    savingsList: {
        results: [],
        total: 0,
        page: 1,
        pageSize: 25,
        isLoading: false,
        error: null
    },
    create_savings_request: false,
    edit_savings_request: false,
    delete_savings_request: false,
    convert_savings_request: false,
    edit_savings_request: false,
    delete_savings_request: false,
    delete_savings_success: false,
    delete_savings_failed: false,
    create_savings_success: false,
    create_savings_failed: false,
    edit_savings_success: false,
    edit_savings_failed: false,
    
    savingsListRequest: false,
    savingsListFailed: false,
    savingsListSuccess: false,
};

const savings = createSlice({
    name: 'savings',
    initialState,
    reducers: {
        listSavingsRequest(state, action) {
            state.list_savings_request = true;
            state.list_savings_success = false;
            state.list_savings_failed = false;
        },
        listSavingsSuccess(state, action) {
            state.list_savings_request = false;
            state.list_savings_success = true;
            state.list_savings_failed = false;
            state.savingsList = action.payload;
        },
        listSavingsFailed(state, action) {
            state.list_savings_request = false;
            state.list_savings_success = false;
            state.list_savings_failed = true;
        },
        createSavingsRequest(state, action) {
            state.create_savings_request = true;
            state.create_savings_success = false;
            state.create_savings_failed = false;
        },
        createSavingsSuccess(state, action) {
            state.create_savings_request = false;
            state.create_savings_success = true;
            state.create_savings_failed = false;
            state.savingsList.results = state.savingsList.results.concat(action.payload);
        },
        createSavingsFailed(state, action) {
            state.create_savings_request = false;
            state.create_savings_success = false;
            state.create_savings_failed = true;
        },
        editSavingsRequest(state, action) {
            state.edit_savings_request = true;
            state.edit_savings_success = false;
            state.edit_savings_failed = false;
        },
        editSavingsSuccess(state, action) {
            state.edit_savings_request = false;
            state.edit_savings_success = true;
            state.edit_savings_failed = false;
            state.savingsList.results = state.savingsList.results.map((savings) => {
                if (savings.id === action.payload.id) {
                    savings = action.payload;
                }
                return savings;
            });
        },
        editSavingsFailed(state, action) {
            state.edit_savings_request = false;
            state.edit_savings_success = false;
            state.edit_savings_failed = true;
        },
        deleteSavingsRequest(state, action) {
            state.delete_savings_request = true;
            state.delete_savings_success = false;
            state.delete_savings_failed = false;
        },
        deleteSavingsSuccess(state, action) {
            state.delete_savings_request = false;
            state.delete_savings_success = true;
            state.delete_savings_failed = false;
            state.savingsList.results = state.savingsList.results.filter((savings) => savings.id !== action.payload.id);
        },
        deleteSavingsFailed(state, action) {
            state.delete_savings_request = false;
            state.delete_savings_success = false;
            state.delete_savings_failed = true;
        },
    }
});

export default savings.reducer;

export const {
    listSavingsRequest,
    listSavingsSuccess,
    listSavingsFailed,
    createSavingsRequest,
    createSavingsSuccess,
    createSavingsFailed,
    editSavingsRequest,
    editSavingsSuccess,
    editSavingsFailed,
    deleteSavingsRequest,
    deleteSavingsSuccess,
    deleteSavingsFailed
} = savings.actions;