import { createContext, useState, useMemo, useContext, useEffect } from "react";


// Theme Context
const ThemeContext = createContext();

export const AppThemeProvider = ({ children }) => {
  const [mode, setMode] = useState("dark"); // Default to dark mode

  const toggleTheme = () => {
    setMode((prevMode) => {
        const newMode = prevMode === "light" ? "dark" : "light";
        window.localStorage.setItem("theme", newMode);
        return newMode;
    });
  };

  useEffect(() => {
    const localMode = window.localStorage.getItem("theme");
    if (localMode) {
      setMode(localMode);
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme }}>
        {children}
    </ThemeContext.Provider>
  );
};

// Custom hook to use theme context
export const useTheme = () => useContext(ThemeContext);
