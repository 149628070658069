import * as React from "react";
import { Grid, Card, Avatar, LinearProgress, Box } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { useParams } from "react-router";
import { useTheme } from "@mui/material/styles";
import Palette from "themes/palette";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import UpdatePersonnal from "./components/UpdatePersonnal";
import { dispatch } from "store/index";
import { useDispatch } from "react-redux";
import { loadingCountriesEffect } from "store/effects/countriesEffects";
import WalletsList from "./components/WalletsList";
import TransactionsList from "./components/TransactionsList";
import PositionsList from "./components/PositionsList";
import BankInfosList from "./components/BankInfos";
import UserFilesList from "./components/UserFiles";
import ReportsList from "../../reports/ReportsList"
import TicketsList from "pages/tickets/TicketsList";
import CallRequests from "pages/callRequests/index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SavingsSubscriptions from "./components/SavingsSubscriptions";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid container sx={{ p: 3 }}>
          {children}
        </Grid>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UsersDetails() {
  const { userId } = useParams();
  const theme = Palette("light", "default");

  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState();
  const navigate = useNavigate()

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  const refetchUser = () => {
    API.get(URLS.USERS.DETAILS(userId, 1))
      .then((response) => {
        const { data } = response;
        setUserDetails(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    API.get(URLS.USERS.DETAILS(userId, 1))
      .then((response) => {
        const { data } = response;
        setUserDetails(data);
        setLoading(false);
      })
      .catch((error) => {
        if(error.response.status == 404){
          toast.error("User account not found!")
          navigate("/users/list");
        }
        setLoading(false);
      });
    dispatch(loadingCountriesEffect());
  }, []);

  return (
    // <MainCard title={"User details"}>
    // </MainCard>
    <Grid container>
      <Grid item md={12}>
        {loading && <LinearProgress />}
      </Grid>
      {!loading && (
        <Grid item md={12}>
          <Card
            sx={{
              height: "300px",
              display: "flex",
              bgcolor: theme.palette.primary.main,
              borderRadius: "1rem",
            }}
            color="primary"
            variant="outlined"
          >
            <Box mt={"auto"} ml={"1rem"} mb={"1rem"}>
              <Avatar
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  height: "100px",
                  width: "100px",
                }}
                src={userDetails?.image}
                alt={userDetails?.first_name + " " + userDetails?.last_name}
              >
                {userDetails?.first_name[0] + userDetails?.last_name[0]}
              </Avatar>
            </Box>
          </Card>
        </Grid>
      )}
      {!loading && userDetails && (
        <Grid item md={12}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Personal informations" {...a11yProps(0)} />
                {!userDetails.is_manager && (
                  <Tab label={"Wallets"} {...a11yProps(1)} />
                )}
                {!userDetails.is_manager && (
                  <Tab label="Transactions" {...a11yProps(2)} />
                )}
                {!userDetails.is_manager && (
                  <Tab label="Positions" {...a11yProps(3)} />
                )}
                {!userDetails.is_manager && (
                  <Tab label="Bank details" {...a11yProps(4)} />
                )}
                {!userDetails.is_manager && (
                  <Tab label="Uploaded files" {...a11yProps(5)} />
                )}
                {!userDetails.is_manager && (
                  <Tab label="Comments" {...a11yProps(6)} />
                )}
                {!userDetails.is_manager && (
                  <Tab label="Tickets" {...a11yProps(7)} />
                )}
                {!userDetails.is_manager && (
                  <Tab label="Call requests" {...a11yProps(8)} />
                )}
                {!userDetails.is_manager && (
                  <Tab label="Savings Subscriptions" {...a11yProps(9)} />
                )}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <UpdatePersonnal prospect={userDetails} groups={[]} refetchUser={refetchUser} />
            </TabPanel>
            {!userDetails.is_manager && (
              <>
                <TabPanel value={value} index={1}>
                  <WalletsList />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <TransactionsList />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <PositionsList />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <BankInfosList />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <UserFilesList />
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <ReportsList />
                </TabPanel>
                <TabPanel value={value} index={7}>
                  <TicketsList />
                </TabPanel>
                <TabPanel value={value} index={8}>
                  <CallRequests />
                </TabPanel>
                <TabPanel value={value} index={9}>
                  <SavingsSubscriptions />
                </TabPanel>
              </>
            )}
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
