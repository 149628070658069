// assets
import {
  ChromeOutlined,
  QuestionOutlined,
  UserOutlined,
  UnlockOutlined,
  WalletOutlined,
  SwapOutlined,
  UserAddOutlined,
  CreditCardOutlined,
  ContainerOutlined,
  PhoneOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  UserOutlined,
  UnlockOutlined,
  WalletOutlined,
  SwapOutlined,
  UserAddOutlined,
  CreditCardOutlined,
  ContainerOutlined,
  PhoneOutlined,
  MoneyCollectOutlined,
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const admin = {
  id: "administration",
  title: "Administration",
  type: "group",
  children: [
    {
      id: "call-requests",
      title: "Call requests",
      type: "item",
      url: "/call-requests",
      icon: icons.PhoneOutlined,
    },
    {
      id: "credit-cards",
      title: "Credit Cards",
      type: "item",
      url: "/credit/cards",
      icon: icons.CreditCardOutlined,
    },
    {
      id: "deposit-wallets",
      title: "Deposit wallets",
      type: "item",
      url: "/deposits/wallets",
      icon: icons.WalletOutlined,
    },
    {
      id: "group-list",
      title: "Groups list",
      type: "item",
      url: "/users/groups",
      icon: icons.UnlockOutlined,
    },
    {
      id: "sample-page",
      title: "Managers",
      type: "item",
      url: "/users/managers",
      icon: icons.UserOutlined,
    },
    {
      id: "tickets-list",
      title: "Tickets",
      type: "item",
      url: "/tickets",
      icon: icons.ContainerOutlined,
    },
    {
      id: "transactions-page",
      title: "Transactions",
      type: "item",
      url: "/transactions/list",
      icon: icons.SwapOutlined,
    },
    {
      id: "transactions-swap",
      title: "Swap transactions",
      type: "item",
      url: "/transactions/swap",
      icon: icons.SwapOutlined,
    },
    {
      id: "wallets-page",
      title: "Wallets",
      type: "item",
      url: "/wallets",
      icon: icons.WalletOutlined,
    },
    {
      id: "savings-page",
      title: "Savings Plans",
      type: "item",
      url: "/savings",
      icon: icons.MoneyCollectOutlined,
    },
    {
        id: 'prospects',
        title: 'Prospects',
        type: 'item',
        url: '/prospects',
        icon: icons.UserOutlined,
        breadcrumbs: false
    },
  ],
};

export default admin;
