// material-ui
import { Typography } from "@mui/material";

// project import
import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadingCountriesEffect } from "store/effects/countriesEffects";
import { loadingUsers } from "store/effects/usersEffect";

// import CreateProspect from './createProspect';
import { LinearProgress, CircularProgress } from "@mui/material";

import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Tooltip from "@mui/material/Tooltip";
// material-ui
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Checkbox,
  Grid,
  TextField,
} from "@mui/material";

// Icons
import {
  DeleteOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  EditOutlined,
} from "@ant-design/icons";

// project import
import Dot from "components/@extended/Dot";
import _ from "lodash";
// import EditProspect from './editProspect';
import LoadingButton from "@mui/lab/LoadingButton";
import SecureLS from "secure-ls";
import moment from "moment";
import { Link } from "react-router-dom";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { useParams } from "react-router";
import NewTransaction from "pages/users/details/components/create/NewTransaction";
import TransactionActions from "pages/users/components/TransactionsActions";
import CopyButton from "components/@extended/CopyButton";
import { formatNumber } from "components/utils/functions";

// import {}

let ls = new SecureLS({ encodingType: "aes" });
const label = { inputProps: { "aria-label": "Checkbox demo" } };

// ==============================|| PROSPECTS LIST ||============================== //

function OrderTableHead({ handleSelectAll, isAllSelected }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {" "}
          <Checkbox
            {...label}
            checked={isAllSelected}
            onClick={() => handleSelectAll(isAllSelected)}
          />
        </TableCell>
        <TableCell>Reference</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Amount</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Date</TableCell>
        <TableCell>Wallet address</TableCell>
        <TableCell>Accept risk</TableCell>
        {/* <TableCell>Wallet</TableCell> */}
        <TableCell>Bank details</TableCell>
        <TableCell>Transaction notes</TableCell>
        <TableCell>Manager notes</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

// ==============================|| ORDER TABLE - STATUS ||============================== //

const OrderStatus = ({ status }) => {
  let color;
  let title;

  switch (status) {
    case 0:
      color = "warning";
      title = "Pending";
      break;
    case 1:
      color = "success";
      title = "Approved";
      break;
    case 2:
      color = "error";
      title = "Rejected";
      break;
    default:
      color = "primary";
      title = "None";
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{title}</Typography>
    </Stack>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.number,
};

// ==============================|| ORDER TABLE ||============================== //

export default function AdminTransactions() {
  const [order] = useState("asc");
  const [orderBy] = useState("trackingNo");
  const [selected] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [transactions, setTransactions] = useState({
    results: [],
    total: 0,
    page_size: 25,
  });
  const [selectedProspect, setSelectedProspect] = useState([]);
  // const [selectedProspect, setSelectedProspect] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [open, setOpen] = useState({ open: false, id: "" });
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState("NORMAL_USERS");

  const { userId } = useParams();

  const {
    loading_users_request,
    delete_propect_success,
    delete_propect_request,
    usersList: { results, count, page: page1 },
  } = useSelector((state) => state.users);
  const { countriesList, loading_countries_success } = useSelector(
    (state) => state.countries
  );

  const { groups } = ls.get("user-details");
  // console.log('user ===========> ', user);
  const handleSelectProspect = (prospect) => {
    setSelectedProspect((prev) => [...prev, prospect.id]);
    if (selectedProspect.length === results.length) {
      setIsAllSelected(true);
    }

    // setIsAllSelected(true);
  };
  const handleSelectAllProspects = (isAllSelected) => {
    if (isAllSelected) {
      setSelectedProspect([]);
      setIsAllSelected(false);
    } else {
      setIsAllSelected(true);
      results.forEach((prospect) => {
        handleSelectProspect(prospect, true);
      });
    }
  };
  const handleSuccess = () => {
    API.get(URLS.TRANSACTIONS.LIST(search))
      .then((resp) => {
        setTransactions(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadingCountriesEffect());
    API.get(URLS.TRANSACTIONS.LIST(search))
      .then((resp) => {
        setTransactions(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [search]);

  useEffect(() => {
    if (delete_propect_success) {
      setOpen({ open: false, id: "" });
    }
  }, [delete_propect_success]);

  const isSelected = (trackingNo) => selected.indexOf(trackingNo) !== -1;

  return (
    <Grid container>
      {loading && (
        <Grid item md={12} my={3}>
          <LinearProgress />
        </Grid>
      )}
      {/* {list_propect_request && results.length === 0 && <LinearProgress />} */}
      <Grid item md={12}></Grid>
      <Grid item mt={4} mb={4} md={4} xs={12}>
        <TextField
          fullWidth
          placeholder={"Search by transaction type, user, wallet, ..."}
          onChange={(event) => setSearch(event.target.value)}
        />
      </Grid>
      {
        transactions?.results.length > 0 &&
      <MainCard
        title="Transactions list"
        secondary={
          <Box
            sx={{
              display: "flex",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Stack justifyContent="end">
                <NewTransaction
                  handleSuccess={handleSuccess}
                  wallets={wallets}
                  bankDetails={bankDetails}
                  actionButton={() => (
                    <Button
                      variant="contained"
                      sx={{ width: "200px", marginLeft: "auto" }}
                      color="primary"
                    >
                      New Transaction
                    </Button>
                  )}
                />
              </Stack>
            </Box>
          </Box>
        }
      >
        <Box>
          {transactions?.results.length > 0 && (
            <TableContainer
              sx={{
                width: "100%",
                overflowX: "auto",
                position: "relative",
                display: "block",
                maxWidth: "100%",
                "& td, & th": { whiteSpace: "nowrap" },
              }}
            >
              <Table
                aria-labelledby="tableTitle"
                sx={{
                  "& .MuiTableCell-root:first-child": {
                    pl: 2,
                  },
                  "& .MuiTableCell-root:last-child": {
                    pr: 3,
                  },
                }}
              >
                <OrderTableHead
                  order={order}
                  orderBy={orderBy}
                  handleSelectAll={handleSelectAllProspects}
                  isAllSelected={isAllSelected}
                />
                <TableBody>
                  {transactions?.results?.map((row1, index) => {
                    const row = { ...row1 };
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${row.id}`;
                    row.countryDetails = _.filter(countriesList, (item) =>
                      item.url.includes(`/${row.country}/`)
                    )[0];

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        aria-checked={selectedProspect.includes(row.id)}
                        tabIndex={-1}
                        key={row.id}
                        selected={selectedProspect.includes(row.id)}
                      >
                        <TableCell>
                          {" "}
                          {row1.type === "CREDIT" ? (
                            <ArrowDownOutlined
                              style={{ fontSize: "30px", color: "#56F000" }}
                              rotate={-30}
                            />
                          ) : (
                            <ArrowUpOutlined
                              style={{ fontSize: "30px", color: "#FF3838" }}
                              rotate={-30}
                            />
                          )}
                        </TableCell>
                        <TableCell align="left">
                        {row.reference} {" "}
                        <CopyButton text={row.reference} title={"Copy the reference"} />
                        </TableCell>
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="left"
                        >
                          {formatNumber(row.amount)} {row.wallet?.currency}
                        </TableCell>
                        <TableCell align="left">
                          {row.status === "PENDING" ? (
                            <span style={{ color: "#FFB302" }}>
                              {row.status}
                            </span>
                          ) : row.status === "VALIDATED" ? (
                            <span style={{ color: "#56F000" }}>
                              {row.status}
                            </span>
                          ) : (
                            <span style={{ color: "#FF3838 " }}>
                              {row.status}
                            </span>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {moment(row.date).format("lll")}
                        </TableCell>
                        <TableCell align="left">{row.wallet_address}</TableCell>
                        <TableCell align="left">
                          {row.user_accept_risk ? "YES" : "NO"}
                        </TableCell>
                        <TableCell align="left">
                          <Link to={"#"}>{row.account_details?.bank_name}</Link>
                        </TableCell>
                        <TableCell align="left">
                          {row.transaction_notes}
                        </TableCell>
                        <TableCell align="left">{row.note}</TableCell>
                        <TableCell align="left" sx={{ display: "flex" }}>
                          <TransactionActions transaction={row}  handleSuccess={handleSuccess} wallets={wallets} bankDetails={bankDetails} />
                          {/* <NewTransaction
                            handleSuccess={handleSuccess}
                            wallets={wallets}
                            wallet={row}
                            isEdition
                            bankDetails={bankDetails}
                            actionButton={() => (
                              <Tooltip title="Edit transaction">
                                <IconButton>
                                  <EditOutlined />
                                </IconButton>
                              </Tooltip>
                            )}
                          />
                          <Tooltip title="Delete user">
                            <IconButton
                              onClick={() =>
                                setOpen({ open: true, id: row.id })
                              }
                            >
                              <DeleteOutlined />
                            </IconButton>
                          </Tooltip>
                          <Dialog
                            open={open.open}
                            onClose={() => setOpen({ open: false, id: "" })}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {"Do you really want to delete?"}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                This action is irreversible. Are you sure to
                                continue ?
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                variant="default"
                                onClick={() => setOpen({ open: false, id: "" })}
                              >
                                Cancel
                              </Button>
                              <LoadingButton
                                loading={delete_propect_request}
                                variant="contained"
                                color="error"
                                // onClick={() => dispatch(deleteProspectEffect(row))}
                              >
                                Yes, delete
                              </LoadingButton>
                            </DialogActions> 
                          </Dialog>*/}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {/* <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={pageSize}
            page={page1 - 1 ?? 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />*/}
            </TableContainer>
          )}
          {!loading && transactions?.results.length === 0 && (
            <Typography
              variant="body2"
              mt={5}
              sx={{ textAlign: "center", width: "100%" }}
            >
              {" "}
              No Transactions for the moment
            </Typography>
          )}
        </Box>
      </MainCard>
      }
    </Grid>
  );
}
