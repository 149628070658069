import {
  Grid,
  Card,
  Typography,
  Stack,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Fade,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";

import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import moment from "moment";
import NewWallet from "./create/NewWallet";
import { MoreOutlined } from "@ant-design/icons";
import { LinearProgress } from "@mui/material";
import "./styles.css";
import "./bootstrap.css";

// Icons
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import HideIfNotAdmin from "components/auth/HideIfNotAdmin";
import Swal from "sweetalert2";
import CopyButton from "components/@extended/CopyButton";
import { formatNumber } from "components/utils/functions";

function WalletMenu({ wallet, handleSuccess }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ position: "absolute", bottom: "1rem", right: "1rem" }}
      >
        <MoreOutlined style={{ color: "#fff" }} />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <NewWallet
          handleSuccess={handleSuccess}
          wallet={wallet}
          isEdition={true}
          actionButton={() => <MenuItem>Update</MenuItem>}
        />
      </Menu>
    </>
  );
}

// ==============================|| PROSPECTS LIST ||============================== //

function OrderTableHead({ handleSelectAll, isAllSelected }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {" "}
          <Checkbox
            checked={isAllSelected}
            onClick={() => handleSelectAll(isAllSelected)}
          />
        </TableCell>
        <TableCell>Reference</TableCell>
        <TableCell>Amount</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Date</TableCell>
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

export default function WalletsList() {
  const { userId } = useParams();
  const [wallets, setWallets] = useState([]);

  const [loading, setLoading] = useState(true);
  const [selectedWallet, setSelectedWallet] = useState();
  const toastId = React.useRef(null);

  const handleSuccess = (wallet) => {
    API.get(URLS.WALLETS.USER(userId)).then((resp) => {
      setWallets(resp.data);
    });
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
        toastId.current = toast("Deleting...", {
          autoClose: false,
          closeButton: true,
        });

        API.delete(URLS.WALLETS.DELETE(selectedWallet.id))
          .then((resp) => {
            toast.update(toastId.current, {
              render: "Wallet successfuly deleted.",
              type: toast.TYPE.SUCCESS,
              autoClose: 5000,
              toastId: toastId,
            });
            handleSuccess()
          })
          .catch((error) => {
            toast.update(toastId.current, {
              render:
                error?.response?.detail ?? "Error while deleting the wallet.",
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              toastId: toastId,
            });
          });
      }
    });
  };

  useEffect(() => {
    API.get(URLS.WALLETS.USER(userId))
      .then((resp) => {
        setWallets(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <Grid container spacing={3}>
      <HideIfNotAdmin>
        <Grid item md={12}>
          <Stack justifyContent="end">
            <NewWallet
              handleSuccess={handleSuccess}
              actionButton={() => (
                <Button
                  variant="contained"
                  sx={{ width: "200px", marginLeft: "auto" }}
                  color="primary"
                >
                  New wallet
                </Button>
              )}
            />
          </Stack>
        </Grid>
      </HideIfNotAdmin>
      <Grid item md={12}>
        <Grid>{loading && <LinearProgress />}</Grid>
      </Grid>
      <Grid item md={6} p={3}>
        <Grid>
          <div class="crypt-deepblue-segment p-2">
            {!loading && wallets?.length === 0 && (
              <Typography sx={{ textAlign: "left" }} variant="body2">
                No Wallet for this user.
              </Typography>
            )}
            {/* <form class="crypt-dash-search">
						<input type="search" placeholder="Search..." name="s" class="crypt-big-search"/>
						<button type="submit">
						    <i class="pe-7s-search"></i>
						</button>
					</form> */}
            <ul class="crypt-big-list crypt-coin-select">
              {wallets.map((wallet) => {
                return (
                  <li key={wallet.id}>
                    <a
                      href="#bitcoin"
                      className={
                        selectedWallet?.id === wallet.id ? "active" : ""
                      }
                      onClick={(event) => {
                        event.preventDefault();
                        setSelectedWallet(wallet);
                      }}
                    >
                      <img
                        src={`/assets/img/${wallet?.currency?.toLowerCase()}.png`}
                        height="25"
                        className="crypt-market-cap-logo pr-2"
                        alt="coin"
                      />{" "}
                      {wallet?.currency}{" "}
                      <p className="fright">
                        <b>{formatNumber(wallet.balance)}</b>
                      </p>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </Grid>
      </Grid>
      <Grid item md={6} p={3}>
        {selectedWallet && (
          <Grid>
            <Typography variant="h3">Wallet transactions</Typography>
            <Box>
              {selectedWallet && (
                <TableContainer
                  sx={{
                    width: "100%",
                    overflowX: "auto",
                    position: "relative",
                    display: "block",
                    maxWidth: "100%",
                    "& td, & th": { whiteSpace: "nowrap" },
                  }}
                >
                  <Table
                    aria-labelledby="tableTitle"
                    sx={{
                      "& .MuiTableCell-root:first-child": {
                        pl: 2,
                      },
                      "& .MuiTableCell-root:last-child": {
                        pr: 3,
                      },
                    }}
                  >
                    <OrderTableHead
                      handleSelectAll={() => {}}
                      isAllSelected={false}
                    />
                    <TableBody>
                      {selectedWallet?.transactions?.map((row1, index) => {
                        const row = { ...row1 };
                        const labelId = `enhanced-table-checkbox-${row.id}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            tabIndex={-1}
                            key={row.id}
                          >
                            <TableCell>
                              {" "}
                              {row1.type === "CREDIT" ? (
                                <ArrowDownOutlined
                                  style={{ fontSize: "30px", color: "#56F000" }}
                                  rotate={-30}
                                />
                              ) : (
                                <ArrowUpOutlined
                                  style={{ fontSize: "30px", color: "#FF3838" }}
                                  rotate={-30}
                                />
                              )}
                            </TableCell>
                            <TableCell align="left">{row.reference} {" "}
                        <CopyButton text={row.reference} title={"Copy the reference"} /></TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              align="left"
                            >
                              {formatNumber(row.amount)} {row.wallet?.currency}
                            </TableCell>
                            <TableCell align="left">
                              {row.status === "PENDING" ? (
                                <span style={{ color: "#FFB302" }}>
                                  {row.status}
                                </span>
                              ) : row.status === "VALIDATED" ? (
                                <span style={{ color: "#56F000" }}>
                                  {row.status}
                                </span>
                              ) : (
                                <span style={{ color: "#FF3838 " }}>
                                  {row.status}
                                </span>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {moment(row.date).format("lll")}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {/* <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={pageSize}
            page={page1 - 1 ?? 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />*/}
                  {selectedWallet?.transactions?.length === 0 && (
                    <Typography sx={{ textAlign: "center" }} variant="body2">
                      No transaction for this wallet
                    </Typography>
                  )}
                </TableContainer>
              )}
            </Box>

            <Box mt={5}>
              <Stack direction="row" spacing={3}>
                <NewWallet
                  handleSuccess={handleSuccess}
                  wallet={selectedWallet}
                  isEdition
                  actionButton={() => (
                    <Button
                      variant="contained"
                      sx={{ width: "200px", marginLeft: "auto" }}
                      color="primary"
                    >
                      Update wallet
                    </Button>
                  )}
                />
                <Button
                  onClick={handleDelete}
                  variant="contained"
                  sx={{ width: "200px", marginLeft: "auto" }}
                  color="error"
                >
                  Delete wallet
                </Button>
              </Stack>
            </Box>

            {/* <div class="crypt-dash-withdraw mt-3 d-block" id="bitcoin">
            <div class="crypt-withdraw-heading border">
              <div class="row">
                <div class="col-md-3">
                  <p>
                    <b>1 BTC</b>
                  </p>
                  <p class="crypt-up">
                    <b>$3,483.59 USDT (0.28%) </b>
                  </p>
                </div>
                <div class="col-md-5">
                  <i class="pe-7s-lock icon-big"></i>
                  <p>
                    <b>Locked: $ 0.00</b>
                  </p>
                </div>
                <div class="col-md-4">
                  <p>
                    <b>Total:</b>
                  </p>
                  <p class="crypt-up">
                    <b> $ 23454.00</b>
                  </p>
                </div>
              </div>
            </div>
            <div class="crypt-withdraw-body bg-white">
              <div class="row">
                <div class="col-md-3">
                  <div
                    class="nav flex-column nav-pills"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      class="nav-link active"
                      id="v-pills-zilliqua-btc-deposit-tab"
                      data-toggle="pill"
                      href="#v-pills-zilliqua-btc-deposit"
                      role="tab"
                      aria-controls="v-pills-zilliqua-btc-deposit"
                      aria-selected="true"
                    >
                      <i class="pe-7s-bottom-arrow"></i> Deposit
                    </a>

                    <a
                      class="nav-link"
                      id="v-pills-zilliqua-btc-withdrawl-tab"
                      data-toggle="pill"
                      href="#v-pills-zilliqua-btc-withdrawl"
                      role="tab"
                      aria-controls="v-pills-zilliqua-btc-withdrawl"
                      aria-selected="false"
                    >
                      <i class="pe-7s-up-arrow"></i> Withdraw
                    </a>

                    <a
                      class="nav-link"
                      id="v-pills-zilliqua-btc-history-tab"
                      data-toggle="pill"
                      href="#v-pills-zilliqua-btc-history"
                      role="tab"
                      aria-controls="v-pills-zilliqua-btc-history"
                      aria-selected="false"
                    >
                      <i class="pe-7s-clock"></i> History
                    </a>

                    <a
                      class="nav-link"
                      id="v-pills-zilliqua-btc-buysell-tab"
                      data-toggle="pill"
                      href="#v-pills-zilliqua-btc-buysell"
                      role="tab"
                      aria-controls="v-pills-zilliqua-btc-buysell"
                      aria-selected="false"
                    >
                      <i class="pe-7s-refresh-2"></i> Buy / Sell
                    </a>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="tab-content" id="v-pills-zilliqua-btc-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="v-pills-zilliqua-btc-deposit"
                      role="tabpanel"
                      aria-labelledby="v-pills-zilliqua-btc-deposit-tab"
                    >
                      <h6 class="text-uppercase">REMAINING TO VIP $3000</h6>
                      <p>
                        Details -{" "}
                        <a href="" class="crypt-up">
                          Get VIP Now
                        </a>
                      </p>
                      <form class="deposit-form">
                        <div class="crypt-radio-boxed">
                          <input
                            type="radio"
                            name="payment-amount"
                            id="payment-btc-amount1"
                            class="payment-amount"
                          />
                          <label for="payment-btc-amount1">
                            $ 30000{" "}
                            <img src="images/vip.svg" width="40" alt="" />
                          </label>
                          <div class="check"></div>
                        </div>
                        <div class="crypt-radio-boxed">
                          <input
                            type="radio"
                            name="payment-amount"
                            id="payment-btc-amount2"
                            class="payment-amount"
                          />
                          <label for="payment-btc-amount2">
                            $ 20000{" "}
                            <img src="images/vip.svg" width="40" alt="" />
                          </label>
                          <div class="check"></div>
                        </div>
                        <div class="crypt-radio-boxed">
                          <input
                            type="radio"
                            name="payment-amount"
                            id="payment-btc-amount3"
                            class="payment-amount"
                          />
                          <label for="payment-btc-amount3">
                            $ 10000{" "}
                            <img src="images/vip.svg" width="40" alt="" />
                          </label>
                          <div class="check"></div>
                        </div>
                        <div class="crypt-radio-boxed">
                          <input
                            type="radio"
                            name="payment-amount"
                            id="payment-btc-amount4"
                            class="payment-amount"
                          />
                          <label for="payment-btc-amount4">$ 5000 </label>
                          <div class="check"></div>
                        </div>
                        <div class="crypt-radio-boxed">
                          <input
                            type="radio"
                            name="payment-amount"
                            id="payment-btc-amount5"
                            class="payment-amount"
                          />
                          <label for="payment-btc-amount5">$ 2000 </label>
                          <div class="check"></div>
                        </div>
                        <div class="crypt-radio-boxed">
                          <input
                            type="radio"
                            name="payment-amount"
                            id="payment-btc-amount6"
                            class="payment-amount"
                          />
                          <label for="payment-btc-amount6">$ 1000 </label>
                          <div class="check"></div>
                        </div>
                        <div class="form-group mt-2">
                          <select class="crypt-image-select" required="">
                            <option value="">Choose A Payment Option</option>
                            <option value="1">Visa</option>
                            <option value="2">Master Card</option>
                            <option value="3">AMEX</option>
                          </select>
                        </div>
                        <div class="input-group input-text-select mb-3">
                          <div class="input-group-prepend">
                            <input
                              placeholder="Amount"
                              type="text"
                              class="form-control crypt-input-lg"
                            />
                          </div>
                          <select
                            class="custom-select"
                            name="inputGroupSelect01"
                          >
                            <option value="1">USD</option>
                            <option value="2">GBP</option>
                            <option value="3">EUR</option>
                          </select>
                        </div>
                        <div class="text-center crypt-up mt-5 mb-5">
                          <p>You will approximately pay</p>
                          <h3>$500</h3>
                        </div>
                        <a href="" class="crypt-button-red-full">
                          Proceed To Payment
                        </a>
                      </form>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="v-pills-zilliqua-btc-withdrawl"
                      role="tabpanel"
                      aria-labelledby="v-pills-zilliqua-btc-withdrawl-tab"
                    >
                      <h4 class="crypt-down">Wire bank transfer</h4>
                      <p>
                        <i class="pe-7s-info"></i> Standard bank transfer will
                        be made up to 2 workdays
                      </p>
                      <form>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            placeholder="Amount"
                            class="form-control"
                            name="amount"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text">USD</span>
                          </div>
                        </div>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            placeholder="Bank Account Number"
                            class="form-control"
                            name="bank-account"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                            >
                              Other Address
                            </button>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" href="#">
                                234235234
                              </a>
                              <a class="dropdown-item" href="#">
                                2343453453
                              </a>
                              <a class="dropdown-item" href="#">
                                234234234234
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Name"
                            name="name"
                          />
                        </div>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Swift Code"
                            name="swift"
                          />
                        </div>
                        <div class="form-group">
                          <div class="form-group">
                            <select class="form-control">
                              <option>Country</option>
                              <option>United States</option>
                              <option>India</option>
                              <option>Japan</option>
                              <option>Korea</option>
                              <option>China</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="recipient-btc"
                            />
                            <label class="form-check-label" for="recipient-btc">
                              Add To recipient
                            </label>
                          </div>
                        </div>
                        <a href="" class="crypt-button-red-full">
                          Initiate Withdraw
                        </a>
                      </form>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="v-pills-zilliqua-btc-history"
                      role="tabpanel"
                      aria-labelledby="v-pills-zilliqua-btc-history-tab"
                    >
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Time</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Currency</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">22:35:59</th>
                            <td class="crypt-down">0.000056</td>
                            <td>BTC</td>
                          </tr>
                          <tr>
                            <th scope="row">22:35:59</th>
                            <td>0.0000564</td>
                            <td>ETH</td>
                          </tr>
                          <tr>
                            <th scope="row">22:35:59</th>
                            <td>0.0000234</td>
                            <td>XHO</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="v-pills-zilliqua-btc-buysell"
                      role="tabpanel"
                      aria-labelledby="v-pills-zilliqua-btc-buysell-tab"
                    >
                      <div class="crypt-boxed-area">
                        <h6 class="crypt-bg-head">
                          <b class="crypt-up">BUY</b> /{" "}
                          <b class="crypt-down">SELL</b>
                        </h6>
                        <div class="row no-gutters">
                          <div class="col-md-12 col-xxl-6">
                            <div class="crypt-buy-sell-form">
                              <p>
                                Buy <span class="crypt-up">BTC</span>{" "}
                                <span class="fright">
                                  Available: <b class="crypt-up">20 BTC</b>
                                </span>
                              </p>
                              <div class="crypt-buy">
                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">Price</span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="0.02323476"
                                    readonly=""
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text">BTC</span>
                                  </div>
                                </div>
                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">Amount</span>
                                  </div>
                                  <input type="number" class="form-control" />
                                  <div class="input-group-append">
                                    <span class="input-group-text">BTC</span>
                                  </div>
                                </div>
                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">Total</span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly=""
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text">BTC</span>
                                  </div>
                                </div>
                                <div>
                                  <p>
                                    Fee:{" "}
                                    <span class="fright">100%x0.2 = 0.02</span>
                                  </p>
                                </div>
                                <div class="text-center mt-5 mb-5 crypt-up">
                                  <p>You will approximately pay</p>
                                  <h4>0.09834 BTC</h4>
                                </div>
                                <div class="menu-green">
                                  <a href="" class="crypt-button-green-full">
                                    Buy
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 col-xxl-6">
                            <div class="crypt-buy-sell-form">
                              <p>
                                Sell <span class="crypt-down">BTC</span>{" "}
                                <span class="fright">
                                  Available: <b class="crypt-down">20 BTC</b>
                                </span>
                              </p>
                              <div class="crypt-sell">
                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">Price</span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="0.02323476"
                                    readonly=""
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text">BTC</span>
                                  </div>
                                </div>
                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">Amount</span>
                                  </div>
                                  <input type="number" class="form-control" />
                                  <div class="input-group-append">
                                    <span class="input-group-text">BTC</span>
                                  </div>
                                </div>
                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">Total</span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly=""
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text">BTC</span>
                                  </div>
                                </div>
                                <div>
                                  <p>
                                    Fee:{" "}
                                    <span class="fright">100%x0.2 = 0.02</span>
                                  </p>
                                </div>
                                <div class="text-center mt-5 mb-5 crypt-down">
                                  <p>You will approximately pay</p>
                                  <h4>0.09834 BTC</h4>
                                </div>
                                <div>
                                  <a href="" class="crypt-button-red-full">
                                    Sell
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          </Grid>
        )}
      </Grid>
      {/* {wallets.map((wallet) => {
        return (
          <Grid key={wallet.id} item md={4}>
            <Card
              variant="outlined"
              sx={{
                p: 2,
                backgroundColor: "#000",
                backgroundImage: `url(${
                  wallet.currency === "BTC"
                    ? "/assets/img/BTC.jpg"
                    : wallet.currency === "USD"
                    ? "/assets/img/USD.jpg"
                    : wallet.currency === "USDT"
                    ? "/assets/img/USDT.png"
                    : wallet.currency === "ETH"
                    ? "/assets/img/ETH.png"
                    : "/assets/img/EUR.png"
                })`,
                height: 250,
                backgroundSize: "30%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top right",
                borderRadius: "1rem",
                position: "relative",
              }}
              rounded
            >
              <WalletMenu wallet={wallet} handleSuccess={handleSuccess} />
              <Stack>
                <img
                  height={"100px"}
                  width={"100px"}
                  src={URLS.BASE_URL + wallet.qr_code}
                  alt="qr code"
                />
                <div style={{ marginTop: "1rem" }}></div>
                <Typography variant="h6" sx={{ color: "#fff" }}>
                  {wallet.address}
                </Typography>
                <Typography variant="h4" sx={{ color: "#fff" }}>
                  {wallet.currency}
                </Typography>
                <Typography variant="h3" sx={{ color: "#fff" }}>
                  {wallet.balance} {}
                </Typography>
                <br />
                <Typography variant="body1" sx={{ color: "#fff" }}>
                  {moment(wallet.created_at).format("MMMM Do YYYY, h:mm:ss a")}
                </Typography>
              </Stack>
            </Card>
          </Grid>
        );
      })} */}
    </Grid>
  );
}
