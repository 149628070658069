import { URLS } from "services/urls/urls";


export const calculateSavingProfit = (subscription) => {

    const investedAmount = subscription.amount;
    let profit = 0;

    switch (subscription.plan_base) {
        case "value":
            
            break;
    
        default:
            profit = investedAmount * (1 + ( subscription.plan.flexible_perc / 100));
            break;
    }

    return profit;
}


export function getRateFromPeriod(plan, period) {
    let rate;
    switch (period) {
      case "1_MONTH":
        rate = plan?.locked_1_month_perc;
        break;
      case "1_QUARTER":
        rate = plan?.locked_1_quarter_perc;
        break;
      case "6_MONTHS":
        rate = plan?.locked_6_months_perc;
        break;
      case "1_YEAR":
        rate = plan?.locked_1_year_perc;
        break;
      case "FLEXIBLE":
        rate = plan?.flexible_perc;
        break;
      default:
        rate = plan?.flexible_perc;
        break;
    }
    return rate;
  }

export const formatUrl = (url) => {
  if (url) {
    if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
      return url;
    }
  }
  return `${URLS.BASE_URL}${url}`;
};


export const formatNumber = (number) => {
  if (number) {
    const numbFixed = Number(number).toFixed(2);
    return String(numbFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};