// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import ScrollTop from "components/ScrollTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auth from "layout/MainLayout/Auth";
import DisconnectInactiveUser from "components/DisconnectInactiveUser";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppThemeProvider } from "contexts/ThemeContext";
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AppThemeProvider>
      <ThemeCustomization>
        <DisconnectInactiveUser>
          <Auth>
            <ToastContainer />
            <ScrollTop>
              <Routes />
            </ScrollTop>
          </Auth>
        </DisconnectInactiveUser>
      </ThemeCustomization>
    </AppThemeProvider>
  </QueryClientProvider>
);

export default App;
